import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion'
import { useMedia } from 'hooks/useMedia'
import { Button } from 'elements/Button/Button'
import { FilterType } from 'typings/api'
import Image from '@next/image'

export type FilterProps = {
  filter: FilterType
  handleFilters: (arg1: string, arg2: string) => void
  resetFilters: () => void
  hasNoFilters: boolean
}

export const EventFilter = ({ filter, handleFilters, resetFilters, hasNoFilters }: FilterProps) => {
  const [openFilterMobile, setOpenFilterMobile] = useState(false)
  const [openFilterDesktop, setOpenFilterDesktop] = useState(false)
  const [openIndexDesktop, setOpenIndexDesktop] = useState(null)
  const formRef = useRef<HTMLFormElement>()
  const { t } = useTranslation()
  const isDesktop = useMedia('md')
  const [indices, setIndices] = useState([])
  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex))
    } else {
      setIndices([...indices, toggledIndex].sort())
    }
  }

  const handleDesktopToggleState = (i: number) => {
    setOpenFilterDesktop((prev) => !prev)
    setOpenIndexDesktop(i)
  }

  const handleReset = () => {
    resetFilters()
    Array.from(document.getElementsByClassName('filterInput')).forEach(
      (inputField: HTMLInputElement) => {
        inputField.checked = false
      },
    )
    setOpenFilterDesktop(false)
  }

  const RemoveFiltersButton = () => {
    return (
      <button
        disabled={hasNoFilters}
        type="button"
        onClick={handleReset}
        className={clsx(
          'group flex items-center text-xs font-bold underline duration-200 ease-in-out disabled:text-gray-medium',
        )}
      >
      <Image
          src="/assets/icons/cross-black-light.svg"
          width={20}
          height={20}
          alt="cross-icon"
          className="mr-2 duration-200 ease-in-out group-disabled:opacity-40"
        />
        {t('Remove Filter')}
      </button>
    )
  }

  if (isDesktop) {
    return (
      <div className="mb-4 items-center bg-gray-light px-10 py-6">
        <menu className="flex w-full items-center gap-8 text-black-light">
          <li>
            <label className="mb-0 text-xl font-bold">{t('Filter')}</label>
          </li>
          <form className="mr-auto flex items-center gap-6" ref={formRef}>
            {filter?.map((item, i) => {
              return (
                <ul key={`${item.key}_${i}`}>
                  <li className="relative">
                    <button
                      type="button"
                      disabled={!item?.values?.length}
                      onClick={() => handleDesktopToggleState(i)}
                      className="group relative flex items-center gap-1 font-medium duration-200 ease-in-out hover:text-red disabled:text-gray-medium"
                    >
                      <Image
                        src="/assets/icons/chevron-down-black-light.svg"
                        width={14}
                        height={8}
                        alt="chevron-icon"
                        className={clsx(
                          'mr-2 mt-1 transform text-black-light duration-150 ease-in-out group-disabled:opacity-40',
                          openFilterDesktop && openIndexDesktop === i ? 'rotate-180' : 'rotate-0',
                        )}
                      />
                      {item.label}
                    </button>
                    <menu
                      className={clsx(
                        'absolute top-full z-10 mt-6 origin-top bg-gray-light p-5 duration-200',
                        openFilterDesktop && openIndexDesktop === i
                          ? 'scale-y-100 shadow-md'
                          : 'scale-y-0',
                      )}
                    >
                      {item.values.map((filterItem, i) => {
                        return (
                          <li key={`${filterItem.label}_${i}`} className="flex items-center py-2">
                            <input
                              type="checkbox"
                              id={`${filterItem.key}_${i}`}
                              value={filterItem.label}
                              name="eventFilters"
                              className="filterInput peer z-10 mr-2 h-5 w-5 appearance-none rounded-[1px] border-[1.25px] border-gray-medium bg-transparent
                        checked:border-black-light hover:cursor-pointer"
                              onChange={() => {
                                handleFilters(item.key, filterItem.key)
                              }}
                            />
                            <Image
                              src="/assets/icons/checkmark-white.svg"
                              width={20}
                              height={20}
                              alt="checkmark-icon"
                              className="invisible absolute p-[1px] peer-checked:visible peer-checked:bg-gray-dark"
                            />
                            <label
                              htmlFor={`${filterItem.key}_${i}`}
                              className="flex cursor-pointer items-center whitespace-nowrap transition-colors duration-200 peer-checked:text-red peer-hover:text-red"
                            >
                              {filterItem.label}
                            </label>
                          </li>
                        )
                      })}
                    </menu>
                  </li>
                </ul>
              )
            })}
          </form>
          <li className="flex items-center">
            <RemoveFiltersButton />
          </li>
        </menu>
      </div>
    )
  }
  return (
    <div className={clsx('relative z-10 block md:hidden', openFilterMobile && 'shadow-lg')}>
      <div className="flex flex-row bg-gray-light">
        <button
          onClick={() => setOpenFilterMobile((prev) => !prev)}
          className="flex flex-1 items-center justify-center py-4 font-bold text-gray-dark"
        >
          {t('Filter')}
          <Image
            src="/assets/icons/filter-gray-dark.svg"
            width={17}
            height={18}
            alt="filter-icon"
            className="ml-2" 
          />
        </button>
      </div>
      {openFilterMobile && (
        <form
          ref={formRef}
          className={clsx(
            'absolute top-full w-full bg-gray-light px-5 pb-10 ',
            openFilterMobile && 'shadow-lg',
          )}
        >
          {filter ? (
            <div className="relative py-8">
              <Accordion onChange={toggleItem} index={indices}>
                {filter?.map((filterItem) => {
                  if (filterItem.values.length)
                    return (
                      <AccordionItem className="overflow-hidden">
                        <AccordionButton>
                          <h3 className="mb-0 flex flex-row items-center text-base font-bold text-black-light">
                            <div className="mr-3 ml-1">
                              <Image
                                src="/assets/icons/chevron-down-black-light.svg"
                                width={14}
                                height={8}
                                alt="chevron-icon"
                              />
                            </div>
                            {filterItem.label}
                          </h3>
                        </AccordionButton>
                        <AccordionPanel>
                          <fieldset>
                            <ul className="mt-2 pl-7">
                              {filterItem.values.map((value, i) => (
                                <li key={`${value.label}_${i}`} className="flex items-center py-2">
                                  <input
                                    type="checkbox"
                                    id={`${value.key}_${i}`}
                                    value={value.label}
                                    name="eventFilters"
                                    className="filterInput peer z-10 mr-2 h-5 w-5 appearance-none rounded-[1px] border-[1.25px] border-gray-medium bg-transparent
                        checked:border-black-light hover:cursor-pointer"
                                    onChange={() => handleFilters(filterItem.key, value.key)}
                                  />
                                  <Image
                                    src="/assets/icons/checkmark-white.svg"
                                    width={20}
                                    height={20}
                                    alt="checkmark-icon"
                                    className="invisible absolute p-[1px] peer-checked:visible peer-checked:bg-gray-dark"
                                  />
                                  <label
                                    htmlFor={`${value.key}_${i}`}
                                    className={
                                      'flex items-center whitespace-nowrap transition-colors peer-checked:text-red peer-hover:text-red'
                                    }
                                  >
                                    {value.label}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </fieldset>
                        </AccordionPanel>
                      </AccordionItem>
                    )
                })}
              </Accordion>
            </div>
          ) : null}
          <RemoveFiltersButton />
          <Button className="mt-5 w-full sm:w-full" onClick={() => setOpenFilterMobile(false)}>
            {t('FilterCTA')}
          </Button>
        </form>
      )}
    </div>
  )
}
