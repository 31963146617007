import useElementSize from '@charlietango/use-element-size'
import { AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion'
import { Icon } from 'elements/Icon/Icon'
import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'
import { PrescriptionStatus, PrescriptionValue } from 'typings/api'

export const Prescription = ({ prescriptionNumber, name, status, timeline }: PrescriptionValue) => {
  const { t } = useTranslation('prescription-status')
  const AnimatedPanel = animated(AccordionPanel)
  const AnimatedItem = animated(AccordionItem)
  const [isOpen, setIsOpen] = useState(false)
  const [buttonRef, { height: buttonHeight }] = useElementSize()
  const [panelRef, { height: panelHeight }] = useElementSize()
  const accItem = useRef<HTMLDivElement>()

  const expand = useSpring({
    config: { friction: 20 },
    height: isOpen
      ? `${Math.floor(panelHeight) + Math.floor(buttonHeight) + 32}px`
      : `${Math.floor(buttonHeight) + 32}px`,
  })

  const fade = useSpring({
    config: { duration: 300 },
    opacity: isOpen ? `1` : `0`,
  })

  const rotate = useSpring({
    config: { friction: 15 },
    rotate: isOpen ? 0 : 90,
  })

  useEffect(() => {
    if (isOpen) {
      const rect = accItem.current.getBoundingClientRect()
      if (window.innerHeight - rect.top < panelHeight) {
        const scrollHeight =
          rect.top - panelHeight > 0 ? panelHeight : panelHeight - (rect.top - panelHeight) * -1
        window.scrollBy({
          top: Math.floor(scrollHeight),
          behavior: 'smooth',
        })
      }
    }
  }, [panelHeight, isOpen])

  const StatusButton = () => {
    switch (status) {
      case PrescriptionStatus.DONE:
        return (
          <>
            {t(`${status}`)} <Icon name="checkmark" className="ml-1" />
          </>
        )
      default:
        return null
    }
  }

  return (
    <AnimatedItem
      className={`mb-4 overflow-hidden md:mb-6 ${isOpen ? 'shadow-lg' : ''}`}
      style={expand}
      ref={accItem}
    >
      <AccordionButton
        ref={buttonRef}
        onClick={() => setIsOpen((prev) => !prev)}
        className="relative w-full bg-gray-light px-4 py-2 text-left md:flex md:p-4"
      >
        <h3 className="mb-2 text-base font-bold md:mb-0 md:mr-2">{t('Prescription Number')}:</h3>
        <div className="absolute top-4 right-4 mr-2 h-3.5 w-3.5 transform md:top-2/4 md:-translate-y-2/4 xl:h-4 xl:w-4 2xl:h-3 2xl:w-7">
          <animated.div style={rotate} className="absolute h-full w-full origin-center">
            <Icon name="minus" className="absolute h-full w-full origin-center" />
          </animated.div>
          <Icon name="minus" className="origin-center2 absolute h-full w-full" />
        </div>
        <p className="m-0">{prescriptionNumber}</p>
      </AccordionButton>

      <div ref={panelRef}>
        <AnimatedPanel style={fade} className="p-4 md:p-6">
          <h4 className="mb-4 text-xl font-normal text-black-light md:mb-6 md:text-3xl">{name}</h4>
          <div className="mb-4 flex items-center">
            <span className="mr-2 font-bold">Status:</span>{' '}
            <div className="flex items-center rounded-full border border-red py-1 px-2 text-xs font-bold text-red md:text-base">
              {status}
            </div>
          </div>
          <ul className="pl-5">
            {timeline.map((pointInTime) => (
              <li
                key={pointInTime.status}
                className="relative pb-4 before:absolute before:-left-5 before:top-2 before:z-10 before:h-[10px] before:w-[10px] before:translate-x-[-1px] before:rounded-full before:border before:border-white before:bg-gray after:absolute after:-left-4 after:top-2 after:h-full after:w-[2px] after:translate-x-[-1px] after:bg-gray last-of-type:pb-0 last-of-type:before:bg-red last-of-type:after:hidden md:pb-6"
              >
                <div className="font-bold">{pointInTime.date}</div>
                <div>{t(`${pointInTime.status}`)}</div>
              </li>
            ))}
          </ul>
        </AnimatedPanel>
      </div>
    </AnimatedItem>
  )
}
