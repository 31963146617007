import Script from 'next/script'
import { useEffect } from 'react'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type FTAPIProps = { secuformUrl: string, spacing:string }

export const FTAPI = ({ secuformUrl, spacing }: FTAPIProps) => {
  const margin = useMarginMaker(spacing)
  useEffect(() => {
    ;(window as any).__ftapi_config = {
      secuformUrl,
      plain: true,
      ftapiHost: 'https://enovis.ftapi.com',
    }

    return () => {
      delete (window as any).__ftapi_config
    }
  }, [secuformUrl])

  return (
    <>
      <Script src="https://enovis.ftapi.com/webjars/js/client.js" strategy="lazyOnload" />
      <div className="flex justify-center" style={margin}>
      <div id="container">
        <div id="app" />
      </div>
      </div>
    </>
  )
}
