import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Headline, HeadlineType } from 'elements/Headline/Headline'
import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import Image from '@next/image'

export type EventProps = {
  title: string
  subtitle?: string
  location: string
  location_link?: string
  date: string
  time?: string
  stand_number?: string
  targetgroup?: string
  description?: string
  event_link?: string
  fees?: string
  asset_title?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  asset_link?: { url: string }
  calendar_link?: any
  filter?: any
}

export const EventCard = ({
  title,
  subtitle,
  location,
  location_link,
  date,
  time,
  stand_number,
  targetgroup,
  description,
  event_link,
  fees,
  asset_title,
  asset_link,
  calendar_link,
  onClick,
}: EventProps) => {
  const { t } = useTranslation()
  return (
    <section className="items-start justify-between border border-gray p-4 shadow-lg md:flex md:p-10">
      <div className="relative md:max-w-[65%] xl:max-w-[50%]">
        <Headline type={HeadlineType.h3} className="mb-1 text-2xl">
          {title}
        </Headline>
        {subtitle ? (
          <Headline type={HeadlineType.h4} className="mb-0 text-base font-normal text-red">
            {subtitle}
          </Headline>
        ) : null}
        <div className="mt-4 flex flex-col md:flex-row md:gap-x-4">
          <span className="flex items-center  text-base text-black-light">
            <Image
              src="/assets/icons/calender-red.svg"
              width={16}
              height={16}
              alt="calender-icon"
              className="mr-2"
            />
            {date}
          </span>
          {time ? (
            <span className="flex items-center text-base text-black-light">
              <Image
                src="/assets/icons/clock-red.svg"
                width={16}
                height={16}
                alt="clock-icon"
                className="mr-2"
              />
              {time}
            </span>
          ) : null}
          <span
            className={clsx(
              'group flex items-center text-black-light',
              location_link && 'location-animation',
            )}
          >
            <Image
              src="/assets/icons/location-red.svg"
              width={16}
              height={16}
              alt="location-icon"
              className="mr-2"
            />
            {location_link ? (
              <Link
                target="_blank"
                className="font-normal underline group-hover:text-black"
                href={location_link}
              >
                {location}
              </Link>
            ) : (
              location
            )}
          </span>
          {stand_number ? (
            <span className="flex flex-wrap items-center text-base text-black-light">
              <span>{`${t('boothNumber')}:`}&nbsp;</span>
              {stand_number}
            </span>
          ) : null}
        </div>
        {targetgroup ? <p className="mt-6 md:mt-8">{targetgroup}</p> : null}
        {description ? (
          <div
            className="richText mt-4 md:mt-6"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        ) : null}
        {event_link ? (
          <Link
            target="_blank"
            className="mt-6 block  font-normal tracking-wider text-black-light underline"
            href={event_link}
          >
            {event_link}
          </Link>
        ) : null}
        {fees ? <p className="mt-6">{fees}</p> : null}
        <span className="flex flex-col items-center gap-4 md:flex-row">
          {calendar_link ? (
            <Link
              target="_blank"
              className="group flex items-center justify-center gap-2 rounded-full border-2 border-red py-1 px-4 text-base font-bold text-red duration-200 hover:bg-red hover:text-white"
              href={calendar_link.url}
            >
              {calendar_link.title}{' '}
              <Image
                src="/assets/icons/export-red.svg"
                width={16}
                height={16}
                alt="export-icon"
                className="mr-2"
              />
            </Link>
          ) : null}
          {asset_link && asset_title ? (
            <Link
              target="_blank"
              href={asset_link?.url ?? '/'}
              className="group relative flex items-center justify-center gap-2 rounded-full border-2 border-red bg-red py-1 px-4 pl-10 text-base font-bold text-white duration-200 hover:bg-white hover:text-red"
            >
              {asset_title}
              <Image
                src="/assets/icons/download-white.svg"
                width={24}
                height={24}
                alt="download-icon"
                className="absolute left-2 opacity-100 group-hover:opacity-0"
              />
              <Image
                src="/assets/icons/download-red.svg"
                width={24}
                height={24}
                alt="download-icon"
                className="absolute left-2 opacity-0 group-hover:opacity-100"
              />
            </Link>
          ) : null}
        </span>
      </div>
      <button onClick={onClick} className="absolute top-4 right-4 md:relative md:top-0 md:right-0">
        <Image src="/assets/icons/cross-black-light.svg" width={24} height={24} alt="cross-icon" />
      </button>
    </section>
  )
}
