import { AccordionProps } from 'components/Accordion/Accordion'
import { ContactTeaserProps } from 'components/ContactTeaser/ContactTeaser'
import { FullWidthTeaserProps } from 'components/FullWidthTeaser/FullWidthTeaser'
import { IframeProps } from 'components/Iframe/Iframe'
import { ImageTextTeaserProps } from 'components/ImageTextTeaser/ImageTextTeaser'
import { LargeStageProps } from 'components/LargeStage/LargeStage'
import { LargeTeaserProps } from 'components/LargeTeaser/LargeTeaser'
import { PrescriptionStatusProps } from 'components/PrescriptionStatus/PrescriptionStatus'
import { SallyProps } from 'components/Sally/Sally'
import { SmallStageProps } from 'components/SmallStage/SmallStage'
import { SmallTeaserSliderProps } from 'components/SmallTeaser/SmallTeaserSlider'
import { TeaserSliderProps } from 'components/TeaserSlider/TeaserSlider'
import { TextModuleProps } from 'components/TextModule/TextModule'
import { TextTeaserProps } from 'components/TextTeaser/TextTeaser'
import { VideoModuleProps } from 'components/VideoModule/VideoModule'
import { TabNaviContent } from 'components/PageTypes/ProductDetailPage/components/TabNavi/TabNavi.content'
import { ImageType } from './image'
import { FTAPIProps } from '~/components/FTAPI/FTAPI'
import { EventModuleProps } from '~/components/EventModule/EventModule'

type Price = { value: number }

export type PriceResponse = {
  total_count?: number
  result: {
    id: string
    price: Price[]
    discount_amount: Price[]
    net_price: Price[]
    discount_percent: Price[]
  }[]
}

export type ReverseGeocodeResponse = { formattedAddress: string | false }
export type GeocodeResponse = { lat: number; lng: number; error?: string }
export const PrescriptionStatus = { DONE: 1, RECEIVED: 2, PROCESSED: 3, APPROVED: 4 } as const
type Status = typeof PrescriptionStatus[keyof typeof PrescriptionStatus]
export type PrescriptionType = {
  '@odata.context': string
  value: PrescriptionValue[]
}

export type PrescriptionValue = {
  id: string
  entryNo: string
  prescriptionNumber: string
  name: string
  status: Status
  timeline: { date: number; status: Status }[]
}

export type DownloadType = {
  bild?: ImageType
  titel: string
  untertitel: string
  uuid: string
  link_text: string
  url: string
  filename: string
  data_type: string
  alt: string
}
type Asset = {
  filter: any
  filename: string
  alt: string
  mimetype: string
  id: string
  filesize: string
  title: string
  url: string
  thumbnail: string
}
export type DownloadFilter = {
  values: { label: string; key: string }[]
  label: string
  key: string
}
export type DownloadCategory = {
  filter: DownloadFilter[]
  titel: string
  assets: Asset[]
  text: string
  untertitel: string
  banner_bild: {
    filename: string
    alt: string
    id: string
    filesize: string
    title: string
    url: string
  }
  banner_titel: string
}

export type NavigationType = { name: string; url: string; children?: NavigationType[] }

export type ContentModule =
  | ({ type: 'rich_text' } & TextModuleProps)
  | ({ type: 'accordion' } & AccordionProps)
  | ({ type: 'full_width_teaser' } & FullWidthTeaserProps)
  | ({ type: 'contact_teaser' } & ContactTeaserProps)
  | ({ type: 'image_text_teaser' } & ImageTextTeaserProps)
  | ({ type: 'sally' } & SallyProps)
  | ({ type: 'large_stage' } & LargeStageProps)
  | ({ type: 'small_stage' } & SmallStageProps)
  | ({ type: 'video_module' } & VideoModuleProps)
  | ({ type: 'large_teaser' } & LargeTeaserProps)
  | ({ type: 'teaser_slider' } & TeaserSliderProps)
  | ({ type: 'small_teaser_slider' } & SmallTeaserSliderProps)
  | ({ type: 'text_teaser' } & TextTeaserProps)
  | ({ type: 'iframe' } & IframeProps)
  | ({ type: 'prescription_status' } & PrescriptionStatusProps)
  | ({ type: 'formular' } & FTAPIProps)
  | ({ type: 'events' } & EventModuleProps)

type OptionValue = { label: string; uid: string }

type ProductOption = { uid: string; title: string; values: OptionValue[] }

type ItemData = { value: string; label: string }

export type ProductVariant = {
  options: { uid: string; value: string }[]
  description: string
  uuid: string
  item_data: ItemData[]
}
export type ProductOverviewVariant = {
  filter: string[]
  path: string
  bild: ImageType
  name: string
  uuid: string
  beschreibung: string
  nummer: string
  label_nummer:string
  neuprodukt: boolean
}

type ContentPage = {
  breadcrumb?: { href: string; text: string }[]
  main: {
    content_modules: ContentModule[]
    products_title: string
    products: ProductOverviewVariant[]
  }
  type: 'content_page'
}

type ProductDetailPage = {
  main: {
    options: ProductOption[]
    product_description: string
    product_name: string
    product_pictures: ImageType[]
    items: ProductVariant[]
    product_information: TabNaviContent
    content_modules: ContentModule[]
  }
  breadcrumb?: { href: string; text: string }[]
  type: 'pdp'
}

export type FilterType = { values: { label: string; key: string }[]; label: string; key: string }[]

export type ProductOverviewPage = {
  main: {
    filter: FilterType
    titel_banner: string
    bannerbild: ImageType
    bannerbild_mobile: ImageType
    titel_produktlistung: string
    content_modules: ContentModule[]
    products: ProductOverviewVariant[]
  }
  breadcrumb?: { href: string; text: string }[]
  type: 'pop'
}

type DownloadsOverviewPage = { type: 'downloads_overview'; main: DownloadType[] }
type DownloadsPage = {
  type: 'downloads'
  main: {
    filter: FilterType
    titel: string
    assets: Asset[]
    text: string
    untertitel: string
    banner_bild: ImageType
    banner_titel: string
  }
  breadcrumb?: { href: string; text: string }[]
}

export type PageType = {
  path: string
  nav: string
  footer: string
  uuid: string
  title: string
  meta_title: string
  meta_description: string
} & (ContentPage | ProductDetailPage | ProductOverviewPage | DownloadsOverviewPage | DownloadsPage)

type OtherSearchResultsType = {
  title: string
  text: string
}
export type SearchType = {
  filter: FilterType
  // entries: {filter: 'products' | 'downloads' | 'andere', content: (ProductOverviewVariant | DownloadType | OtherSearchResultsType)[]}[]
  entries: { filter: 'products' | 'downloads' | 'andere'; content: any }[]
}
