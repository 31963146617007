import { Accordion } from 'components/Accordion/Accordion'
import { ContactTeaser } from 'components/ContactTeaser/ContactTeaser'
import { FullWidthTeaser } from 'components/FullWidthTeaser/FullWidthTeaser'
import { Iframe } from 'components/Iframe/Iframe'
import { ImageTextTeaser } from 'components/ImageTextTeaser/ImageTextTeaser'
import { LargeStage } from 'components/LargeStage/LargeStage'
import { LargeTeaser } from 'components/LargeTeaser/LargeTeaser'
import { PrescriptionStatus } from 'components/PrescriptionStatus/PrescriptionStatus'
import { Sally } from 'components/Sally/Sally'
import { SmallStage } from 'components/SmallStage/SmallStage'
import { SmallTeaserSlider } from 'components/SmallTeaser/SmallTeaserSlider'
import { TeaserSlider } from 'components/TeaserSlider/TeaserSlider'
import { TextModule } from 'components/TextModule/TextModule'
import { TextTeaser } from 'components/TextTeaser/TextTeaser'
import { VideoModule } from 'components/VideoModule/VideoModule'
import { ContentModule } from 'typings/api'
import { EventModule } from '~/components/EventModule/EventModule'
import { FTAPI } from '~/components/FTAPI/FTAPI'
import { log } from './log'

export const selectContentModule = (mod: ContentModule, i: number) => {
  try {
    if (typeof mod === 'undefined') {
      throw new Error('no module passed')
    }
    const key = `Content-Module-${mod.type}-${i}`

    switch (mod.type) {
      case 'rich_text':
        return <TextModule key={key} {...mod} />
      case 'accordion':
        return <Accordion key={key} {...mod} />
      case 'full_width_teaser':
        return <FullWidthTeaser key={key} {...mod} />
      case 'contact_teaser':
        return <ContactTeaser key={key} {...mod} />
      case 'image_text_teaser':
        return <ImageTextTeaser key={key} {...mod} />
      case 'sally':
        return <Sally key={key} {...mod} />
      case 'large_stage':
        return <LargeStage key={key} {...mod} />
      case 'small_stage':
        return <SmallStage key={key} {...mod} />
      case 'video_module':
        return <VideoModule key={key} {...mod} />
      case 'large_teaser':
        return <LargeTeaser key={key} {...mod} />
      case 'teaser_slider':
        return <TeaserSlider key={key} {...mod} />
      case 'small_teaser_slider':
        return <SmallTeaserSlider key={key} {...mod} />
      case 'text_teaser':
        return <TextTeaser key={key} {...mod} />
      case 'iframe':
        return <Iframe key={key} {...mod} />
      case 'prescription_status':
        return <PrescriptionStatus key={key} {...mod} />
      case 'formular':
        return <FTAPI key={key} {...mod} />
      case 'events':
        return <EventModule key={key} {...mod} />
      default:
        if (process.env.NODE_ENV === 'development') {
          // @ts-ignore
          console.info(`Notice: ${mod.type} is not yet implemented in the parser`)
        }
        return null
    }
  } catch (e) {
    log(e.message)
    return null
  }
}
