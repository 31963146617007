import { useState } from 'react'
import { Headline, HeadlineType } from 'elements/Headline/Headline'
import { Link } from 'elements/Link/Link'
import { EventCard, EventProps } from './EventCard'
import { EventFilter } from './EventFilter'
import { FilterType } from 'typings/api'
import clsx from 'clsx'
import Image from '@next/image'

export type EventModuleProps = {
  events: [EventProps]
  filter: FilterType
}

export const EventModule = ({ events, filter }: EventModuleProps) => {
  const [currIndex, setCurrIndex] = useState([])
  const [userFilter, setUserFilter] = useState(Array(filter.length).fill([]))
  const hasNoFilters = userFilter.every((e) => e.length === 0)

  const handleFilters = (key: string, value: string) => {
    filter?.forEach((item, i) => {
      if (item.key === key) {
        setUserFilter(
          userFilter.map((userFilterElement, index) => {
            if (i === index && !userFilter[i].includes(value)) {
              return [...userFilter[i], value]
            } else if (i === index) {
              userFilter[i].splice(userFilter[i].indexOf(value), 1)
              return userFilter[i]
            } else return userFilterElement
          }),
        )
      }
    })
  }

  const resetFilters = () => {
    setUserFilter(Array(filter.length).fill([]))
  }

  return (
    <section className="max-w-[100vw] px-4 pb-10 md:px-20">
      {filter ? (
        <EventFilter
          filter={filter}
          handleFilters={handleFilters}
          resetFilters={resetFilters}
          hasNoFilters={hasNoFilters}
        />
      ) : null}
      <div className="border-gray py-7 md:border-y md:py-10">
        {events
          ? events.map((event, i) => {
              const sortedFilters = userFilter.map((filter, i) => {
                if (filter.length === 0) return true
                else if (event.filter.some((filter: string) => userFilter[i].includes(filter))) {
                  return true
                } else return false
              })

              if (hasNoFilters || !sortedFilters.includes(false)) {
                return (
                  <div key={`${event.title}_${i}`} className="relative w-full text-left">
                    {currIndex.includes(i) ? (
                      <EventCard
                        {...event}
                        onClick={() => setCurrIndex(currIndex.filter((item) => item !== i))}
                      />
                    ) : (
                      <button
                        onClick={() => setCurrIndex([...currIndex, i])}
                        className="relative flex w-full flex-col justify-between border-t border-gray bg-transparent py-4 duration-200 hover:bg-gray-light md:border-none"
                      >
                        <span className="flex w-full flex-row-reverse items-center justify-between gap-4 md:w-auto md:flex-row md:justify-start">
                          <Image
                            src="/assets/icons/information-red.svg"
                            width={24}
                            height={24}
                            alt="information-icon"
                          />
                          <Headline type={HeadlineType.h3} className="mb-0 text-xl md:text-2xl">
                            {event.title}
                          </Headline>
                        </span>
                        <div className="md:md-0 mt-2 flex w-full justify-between md:ml-10 md:w-auto md:justify-start md:gap-x-4">
                          <span className="flex items-center text-black-light">
                            <Image
                              src="/assets/icons/calender-red.svg"
                              width={16}
                              height={16}
                              alt="calender-icon"
                              className="mr-2"
                            />
                            {event.date}
                          </span>
                          <span
                            className={clsx(
                              'group flex items-center text-gray-dark',
                              event.location_link && 'location-animation',
                            )}
                          >
                            <Image
                              src="/assets/icons/location-red.svg"
                              width={16}
                              height={16}
                              alt="location-icon"
                              className="mr-2"
                            />
                            {event.location_link ? (
                              <Link
                                target='_blank'
                                className="group-hover:text-black"
                                href={event.location_link}
                              >
                                {event.location}
                              </Link>
                            ) : (
                              event.location
                            )}
                          </span>
                        </div>
                      </button>
                    )}
                  </div>
                )
              }
            })
          : null}
      </div>
    </section>
  )
}
