import { useMedia } from 'hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type IframeProps = { url: string; width?: number; height?: number; spacing?: string }

export const Iframe = ({ url, width, height, spacing }: IframeProps) => {
  const isDesktop = useMedia('lg')
  const margin = useMarginMaker(spacing).marginBottom

  return (
    <iframe
      className="mx-auto lg:max-w-text"
      src={url}
      style={{
        width: width && isDesktop ? `${width}%` : `100%`,
        height: height ? `${height}px` : `500px`,
        marginBottom: margin
      }}
    ></iframe>
  )
}
