import { usePrescriptionStatus } from 'hooks/usePrescriptionStatus'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Accordion } from '@reach/accordion'
import { Spinner } from 'components/Spinner/Spinner'
import { Prescription } from './components/Prescription'
import { useEffect } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type PrescriptionStatusProps = {
  url: string
  legalText?: string
  checkboxLabel?: string
  mouseOver?: string
  spacing?: string
}
type FormValues = { insuranceNumber: string; consent: boolean }

const placeholderLegalText =
  '<p>Wir verarbeiten die von Ihnen eingegebene Sozialversicherungsnummer in Verbindung mit weiteren personenbezogenen Daten, die uns zu Ihrer aktuellen Verordnung vorliegen, um Ihnen eine Auskunft zum Status Ihrer Versorgung geben zu können. Die Statusinformation wird sofort auf dieser Website ausgegeben und kann folgende Werte haben:<br/>Weitere Informationen zur Datenverarbeitung der Ormed GmbH entnehmen Sie bitte unserer Website: <a href="https://www.djoglobal.de/datenschutz/datenschutzerklaerung">https://www.djoglobal.de/datenschutz/datenschutzerklaerung</a></p>'
const placeholderLabel =
  'Ich beauftrage die einmalige Verarbeitung der genannten Daten zur Bereitstellung und direkten online Ausgabe des aktuellen Status meiner Versorgung.'
const placeholderRequiredText = 'Bitte stimmen sie der Datenverarbeitung zu'

export const PrescriptionStatus = ({
  url,
  legalText = placeholderLegalText,
  checkboxLabel = placeholderLabel,
  mouseOver = placeholderRequiredText,
  spacing,
}: PrescriptionStatusProps) => {
  const { t } = useTranslation('prescription-status')
  const margin = useMarginMaker(spacing)
  const router = useRouter()
  const insuranceId = router.query['insurance-number']
  const insuranceNumber = (Array.isArray(insuranceId) ? insuranceId.join() : insuranceId) ?? ''
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      insuranceNumber: '',
      consent: false,
    },
  })

  useEffect(() => {
    setValue('insuranceNumber', insuranceNumber)
  }, [insuranceNumber, setValue])

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await router.push(
      {
        pathname: router.pathname,
        query: { urlKey: router.query.urlKey, 'insurance-number': data.insuranceNumber },
      },
      undefined,
      { shallow: true },
    )
  }
  const { data, isLoading, isIdle } = usePrescriptionStatus(
    { number: insuranceNumber, url },
    {
      enabled: !!insuranceNumber,
    },
  )

  return (
    <div className="p-4 md:p-0" style={margin}>
      <form onSubmit={handleSubmit(onSubmit)} className="md:bg-gray-light md:py-4">
        <div className="md:mx-auto md:flex md:max-w-[768px] md:items-center">
          <label htmlFor="insurance-number" className="mb-2 block font-bold md:mb-0 md:mr-4">
            {t('Insurance Number')}
          </label>
          <div className="mb-8 flex flex-row md:m-0 md:flex-1">
            <input
              {...register('insuranceNumber', {
                required: true,
              })}
              id="insurance-number"
              placeholder="A123456789"
              className={clsx(
                'w-0 flex-shrink flex-grow border border-gray-light py-2 px-4',
                errors.insuranceNumber && 'border-red',
              )}
            />

            <button
              type="submit"
              className="flex-grow-0 bg-red py-3 px-4 uppercase tracking-wide text-white md:py-2 md:px-10"
            >
              {t('Search')}
            </button>
          </div>
        </div>
        {errors.insuranceNumber && (
          <p className="mb-0 mt-2 text-center text-red">{t('Insurance Number required')}</p>
        )}
        <div
          className="mt-4 md:mx-auto md:max-w-[768px]"
          dangerouslySetInnerHTML={{ __html: legalText }}
        ></div>
        <div className="mt-4 md:mx-auto md:max-w-[768px]">
          <input
            className={clsx(
              'cursor-pointer',
              errors.consent && 'outline outline-1 outline-red-light',
            )}
            id="consent"
            type="checkbox"
            {...register('consent', { required: mouseOver })}
          />
          <label htmlFor="consent" className="ml-2 cursor-pointer">
            {checkboxLabel}
          </label>
          {errors.consent && <p className="mb-0 mt-2 text-center text-red">{mouseOver}</p>}
        </div>
      </form>
      {isLoading ? (
        <div className="flex justify-center">
          <Spinner fullsize />
        </div>
      ) : null}
      {data?.value?.length ? (
        <div className="mb-10 md:mx-auto md:max-w-[768px]">
          <h2 className="mb-4 text-xl font-medium text-red md:mb-6 md:text-3xl">
            {t('Prescription Status')}
          </h2>
          <Accordion multiple collapsible>
            {data.value.map((prescription) => (
              <Prescription key={prescription.prescriptionNumber} {...prescription} />
            ))}
          </Accordion>
        </div>
      ) : (
        !isIdle &&
        !isLoading && (
          <div className="mb-10 md:mx-auto md:max-w-[768px]">
            <p>{t('Nothing found')}</p>
          </div>
        )
      )}
    </div>
  )
}
