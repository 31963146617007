import { fetcher } from 'lib/fetchJson'
import { QueryObserverOptions, useQuery } from 'react-query'
import { PrescriptionType } from 'typings/api'

export const usePrescriptionStatus = (
  { number, url }: { number: string; url: string },
  options?: QueryObserverOptions<PrescriptionType>,
) => {
  const query = useQuery<PrescriptionType, Error>(
    ['/api/prescription-status', number, url],
    async () =>
      await fetcher<PrescriptionType>(
        `/api/prescription-status/${number}`,
      ),
    options,
  )

  return query
}
